<template>
  <div class="container">
    <div class="education-curriculum mt-4">
      <h4 style="text-align: center">Рабочий учебный план</h4>

      <!-- Create Education Curriculum Modal -->
      <div class="modal fade" id="createEducationCurriculum" tabindex="-1"
           aria-labelledby="createEducationCurriculumLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Добавление</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

              <div class="col-md-12 mt-4">
                <div>
                  <div class="row mt-4">
                    <div class="col-md-3">
                      <p>Образовательная программа</p>
                    </div>
                    <div class="col-md-9">
                      <DropdownListEP
                          :options="options"
                          @change="changeSelectEducationProgramData"
                          @search="onSearch"/>
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <label class="col-md-3 col-form-label">Курс</label>
                  <div class="col-md-9">
                    <select class="form-control form-select" name="study_course" id="study_course"
                            @input="changeNewEducationCurriculumData('study_course', $event)">
                      <option selected>Выберите курс</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                </div>


                <div class="row mt-4">
                  <div class="col-md-3">
                    <p>Учебный год</p>
                  </div>
                  <div class="col-md-9">
                    <select class="form-control form-select" name="academic_year" id="academic_year"
                            @input="changeNewEducationCurriculumData( 'sp_academic_year_id', $event)">
                      <option
                          v-for="(item, index) in [{id: 0, name: 'Выберите учебный год'}, ...educationCurriculum_form.academicYear]"
                          :value="item.id"
                          :key="index">{{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-3">
                    <p>Форма обучения</p>
                  </div>
                  <div class="col-md-9">
                    <select class="form-control form-select" name="study_form" id="study_form"
                            @input="changeNewEducationCurriculumData('study_form_id', $event)">
                      <option
                          v-for="(item, index) in [{id: 0, name: 'Выберите форму обучения'}, ...educationCurriculum_form.studyForm]"
                          :value="item.id"
                          :key="index">{{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        @click="submitEducationCurriculum">
                  Создать
                </button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                  Закрыть
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Create Program Modal -->

      <div class="row mt-4">
        <div class="col-md-12 mb-3">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#createEducationCurriculum">
            <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить РУП
          </button>
        </div>
        <div class="col-md-3">
          <select class="form-control form-select" id="filterFilterDepartments"
                  @change="changeFilterDepartments">
            <option v-for="(item, index) in [{id: 0, name_ru: 'Фильтрация по кафедрам Все'}, ...departments]"
                    :value="item.id"
                    :key="index"
                    :selected="item.id==departmentID">{{ item.name_ru }}
            </option>
          </select>
        </div>

        <div class="col-md-3">
          <select class="form-control form-select" name="academic_year" @change="changeFilterYear">
            <option
                v-for="(item, index) in [{id: 0, name: 'Выберите учебный год'}, ...educationCurriculum_form.academicYear]"
                :value="item.id"
                :key="index"
                :selected="item.id==academic_year_id">{{ item.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3">
          <select class="form-control form-select" name="academic_year" @change="changeFilterLanguage">
            <option
                v-for="(item, index) in [{id: 0, native_name: 'Выберите язык'}, ...languages]"
                :value="item.id"
                :key="index"
                :selected="item.id==language_id">{{ item.native_name }}
            </option>
          </select>
        </div>

        <div class="col-md-3">
          <select class="form-control form-select" name="academic_year" @change="changeFilterAdmissionYear">
            <option
                v-for="(item, index) in [{admission_year: 'Выберите год набора'}, ...admission_years]"
                :value="item.admission_year"
                :key="index"
                :selected="item.admission_year==admission_year">{{ item.admission_year }}
            </option>
          </select>
        </div>
      </div>


      <!-- table start -->
      <div class="table-responsive-md mt-4">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">Наименование ОП</th>
            <th scope="col">Уровень обучения</th>
            <th scope="col">Форма обучения</th>
            <th scope="col">Учебный год</th>
            <th scope="col">Язык обучения</th>
            <th scope="col">Год набора</th>

            <th scope="col">Курс</th>

          </tr>
          </thead>
          <tbody>

          <tr v-for="(item,index) in educationCurriculum_form.educationCurriculumInfos"
              :key="index">
            <th scope="row">{{ index + 1 }}</th>
            <td>
              <router-link
                  :to="'/education-curriculum-info?education_program_id=' + item.education_program_id + '&study_course=' + item.study_course +
                  '&academic_year_id='+item.sp_academic_year_id + '&education_speciality_name='+item.educationProgram.education_speciality_name
                  +'&academic_year_name='+item.spAcademicYear.name + '&admission_year='+item.educationProgram.admission_year" target="_blank">
                {{ item.educationProgram.education_speciality_name }}
              </router-link>
            </td>
            <td>{{ item.educationProgram.studyLevel.name }}</td>
            <td>{{ item.studyForm.name }}</td>
            <td>{{ item.spAcademicYear.name }}</td>
            <td>{{ item.educationProgram.language.native_name }}</td>
            <td>{{ item.educationProgram.admission_year }}</td>

            <td>{{ item.study_course }}</td>

          </tr>
          </tbody>
        </table>

        <nav v-if="pageCount" class="d-flex justify-content-center" aria-label="Page navigation example">


          <Paginate
              v-model="page"
              :page-count="pageCount"
              :click-handler="paginateHandler"
              :prev-text="'Предыдущий'"
              :next-text="'Следующий'"
              :container-class="'pagination'"
              :page-class="'page-item'">
          </Paginate>

        </nav>
      </div>
      <!-- table end -->

    </div>
  </div>


</template>

<script>

  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import DropdownListEP from "@/components/common/DropdownListEP.vue";
  import Paginate from '../common/Paginate.vue'

  export default {
    name: "EducationCurriculum",
    components: {
      DropdownListEP,
      Paginate
    },


    data() {
      return {
        options: [],
        educationProgram: null,
        page: +this.$route.query.page || 1,
        departmentID: 0,
        academic_year_id: 0,
        language_id: 0,
        admission_year: 0,

        admission_years: [
          {
            "admission_year": "2018"
          },
          {
            "admission_year": "2019"
          },
          {
            "admission_year": "2020"
          },
          {
            "admission_year": "2021"
          },
          {
            "admission_year": "2022"
          },
          {
            "admission_year": "2023"
          },
        ],

        languages: [
          {
            "id": "137",
            "native_name": "Русский язык"
          },
          {
            "id": "82",
            "native_name": "Қазақ тілі"
          },
          {
            "id": "39",
            "native_name": "English"
          }
        ]

      }
    },
    computed: {
      ...mapState('educationCurriculum', ['educationCurriculum_form', 'pageCount']),
      ...mapState('vacancy', ['departments']),
    },

    methods: {
      ...mapActions('educationCurriculum', ['GET_STUDY_FORM_NAMES', 'GET_SP_ACADEMIC_YEAR', 'GET_EDUCATION_CURRICULUM_DATA',
        'POST_EDUCATION_CURRICULUM_DATA', 'GET_EDUCATION_PROGRAMS_BY_NAME']),
      ...mapActions('vacancy', ['GET_DEPARTMENTS']),
      ...mapMutations('educationCurriculum', ['SET_SELECTED_EDUCATION_PROGRAM_ID', 'SET_NEW_EDUCATION_CURRICULUM_DATA']),


      async paginateHandler(page) {
        this.page = page
        await this.changePage()
      },
      async changePage() {
        this.$router.push(`${this.$route.path}?page=${this.page}`)
        await this.GET_EDUCATION_CURRICULUM_DATA({
          page: this.page,
          department_id: this.departmentID,
          academic_year_id: this.academic_year_id,
          language_id: this.language_id,
          admission_year: this.admission_year
        })
      },

      changeNewEducationCurriculumData(property, e, val = 'value') {
        const value = e.target[val]
        this.SET_NEW_EDUCATION_CURRICULUM_DATA({property, value})
      },
      async submitEducationCurriculum() {
        await this.POST_EDUCATION_CURRICULUM_DATA().then(res => {
          if (res.success == true) {
            this.GET_EDUCATION_CURRICULUM_DATA()

            //this.GET_FORMATION_EDUCATION_PROGRAM_DATA()
            this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
          } else {
            const errorText = res.errors[0].map(err => err.message).join('\n');
            this.$error({title: 'Добавление', text: errorText})
          }
        })
      },
      async changeSelectEducationProgramData(educationProgram) {
        console.log(educationProgram, 'changeSelectEducationProgramData')
        this.educationProgram = educationProgram
        if (typeof educationProgram.id != "undefined") {
          //let studyCourse = 2021 - +(educationProgram.admission_year)
          //await this.SET_SELECTED_STUDY_COURSE(studyCourse)

          await this.SET_SELECTED_EDUCATION_PROGRAM_ID(educationProgram.id)

        }


      },
      onSearch(search) {
        if (search.length > 3) {
          this.GET_EDUCATION_PROGRAMS_BY_NAME(search).then(json => (this.options = json));
        }
      },

      async changeFilterDepartments(e) {
        this.departmentID = e.target.value
        await this.changePage()
      },
      async changeFilterYear(e){
        this.academic_year_id = e.target.value
        await this.changePage()
      },
      async changeFilterLanguage(e){
        this.language_id = e.target.value
        await this.changePage()
      },
      async changeFilterAdmissionYear(e){
        this.admission_year = e.target.value
        await this.changePage()
      },

    },
    async mounted() {
      await this.GET_DEPARTMENTS()
      await this.GET_EDUCATION_CURRICULUM_DATA({
        page: this.page,
        department_id: this.departmentID,
        academic_year_id: this.academic_year_id,
        language_id: this.language_id,
        admission_year: this.admission_year
      })
      await this.GET_SP_ACADEMIC_YEAR()
      await this.GET_STUDY_FORM_NAMES()
    }
  }
</script>

<style scoped>

</style>